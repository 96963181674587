import githubIcon from './github.svg'
import emailIcon from './email.svg'
import mobileIcon from './mobile.svg'
import telegramIcon from './telegram.svg'
import twitterIcon from './twitter.svg'
import linkedInIcon from './linkedin.svg'

export {
  githubIcon as github,
  emailIcon as email,
  mobileIcon as mobile,
  telegramIcon as telegram,
  twitterIcon as twitter,
  linkedInIcon as linkedin,
}
