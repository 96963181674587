module Main exposing (main)

import Browser exposing (Document)
import Dict exposing (Dict)
import Html exposing (Html, a, div, footer, h1, h3, header, main_, nav, p, span, text)
import Html.Attributes exposing (class, href)
import Job exposing (Job)
import Contact exposing (Contact)
import Json.Decode as Dec
import Nav exposing (NavItem)
import Svg exposing (svg, use)
import Svg.Attributes exposing (xlinkHref)


type alias Flags =
    Dec.Value


type alias Config =
    { icons : Dict String String
    , contacts: List Contact
    , jobs : List Job
    }


decodeConfig : Dec.Decoder Config
decodeConfig =
    Dec.map3 Config
        (Dec.field "icons" (Dec.dict Dec.string))
        (Dec.field "contacts" (Dec.list Contact.decoder))
        (Dec.field "jobs" (Dec.list Job.decoder))


type alias Model =
    { jobs : List Job
    , contacts: List Contact
    , icons : Dict String String
    , errors : List String
    }


type alias Msg =
    ()


init : Flags -> ( Model, Cmd Msg )
init flags =
    let
        model =
            case Dec.decodeValue decodeConfig flags of
                Ok config ->
                    { jobs = config.jobs
                    , contacts = config.contacts
                    , icons = config.icons
                    , errors = []
                    }

                Err err ->
                    { jobs = []
                    , contacts = []
                    , icons = Dict.empty
                    , errors = [ Dec.errorToString err ]
                    }
    in
    ( model, Cmd.none )


update : Msg -> Model -> ( Model, Cmd Msg )
update _ model =
    ( model, Cmd.none )


view : Model -> Document Msg
view model =
    { title = "Chakrit.net"
    , body = viewLayout model
    }


viewLayout : Model -> List (Html Msg)
viewLayout model =
    [ div [ class "container" ]
        [ viewHeader model
        , viewBody model
        , viewFooter model
        ]
    ]


viewHeader : Model -> Html Msg
viewHeader model =
    header []
        [ h1 [] [ text "Chakrit Wichian" ]
        , h3 [] [ text "Bangkok, Thailand" ]
        , Contact.view model.icons model.contacts
        ]


viewBody : Model -> Html Msg
viewBody model =
    main_ []
        [ div [] <|
            if List.isEmpty model.errors then
                List.indexedMap (\n -> Job.view (modBy 2 n == 0)) model.jobs

            else
                List.map (\s -> span [] [ text s ]) model.errors
        ]


viewFooter : Model -> Html Msg
viewFooter _ =
    footer []
        [ p []
            [ text "Have questions? "
            , a [ href "mailto:service@chakrit.net" ] [ text "Get in touch" ]
            ]
        , p [] [ text "Copyright © 2024 Chakrit Wichian. All rights reserved." ]
        ]


main : Program Flags Model Msg
main =
    Browser.document
        { init = init
        , view = view
        , update = update
        , subscriptions = \_ -> Sub.none
        }
